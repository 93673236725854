import { AdLinkForm } from 'reducers/announce/announce.types'
import { NodeHeaders } from 'server/NodeHeaders'
import { SeoLinkApi } from 'reducers/seoLink'
import { fetchQueryApi } from 'api/fetchApi'
import { operations } from 'api/generated/announcement_other'

export const fetchSeoLinksApi = (
  form: AdLinkForm,
  nodeHeaders?: NodeHeaders
) => {
  return fetchQueryApi<
    SeoLinkApi[],
    operations['announce_links_groups_getLinksTree']['parameters']['query']
  >(
    '/announcement/links/groups',
    {
      whoAmI: form.whoAmI,
      lookingFor: form.lookingFor,
      datingGoal: 1, // заглушка для временного отображения, TODO: будем переделывать.
      location: form.location!,
    },
    {
      headers: { ...nodeHeaders },
    }
  )
}
